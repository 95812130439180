<template>
    <div class="pagenotfound-content">
        <v-container id="intro" class="cyan text-center white--text mb-4" fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" md="6">
                    <h1 class="mb-5"  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">404</h1>
                    <h2 class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">
                        Seite nicht gefunden
                    </h2>
                    <v-btn
                        class="mt-5"
                        color="primary"
                        block
                        href="/"
                        x-large
                    >
                        Zurück zur Startseite
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>
export default {
    name: "404",

    data: () => ({
        //
    })
};
</script>
